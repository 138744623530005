<div class="return-to-app-container">
  <div class="return-to-app">
    <a (click)="goBackToProduct()">
      <mat-icon class="back-to-app-icon">arrow_back</mat-icon> Back </a>
  </div>
</div>
<div class="change-password-container">
  <div class="title">Change password</div>
  <div class="change-password-form-container">
    <div class="change-password-form">
      <div class="change-password-form-title">Changing password for {{userName}}</div>
      <form [formGroup]="changePasswordForm">
        <mat-form-field class="account-setting-input"
                        color="accent">
          <input matInput
                 id="current-password"
                 placeholder="Current password"
                 [type]="showPasswords ? 'text' : 'password'"
                 formControlName="currentPassword"
                 [formGroup]="changePasswordForm">
          <mat-icon matSuffix
                    class="good-password-color password-validity-icon"
                    *ngIf="currentPasswordCtrl.valid">done</mat-icon>
          <mat-error *ngIf="currentPasswordCtrl.invalid && (currentPasswordCtrl.dirty || currentPasswordCtrl.touched)"> The
            current password is required.</mat-error>
        </mat-form-field>
        <mat-form-field class="account-setting-input"
                        color="accent">
          <input matInput
                 id="new-password"
                 placeholder="New password"
                 [type]="showPasswords ? 'text' : 'password'"
                 formControlName="newPassword"
                 [formGroup]="changePasswordForm">
          <mat-icon matSuffix
                    class="good-password-color password-validity-icon"
                    *ngIf="newPasswordCtrl.valid && changePasswordForm.value.currentPassword !== changePasswordForm.value.newPassword"> done</mat-icon>
          <mat-error *ngIf="newPasswordCtrl.errors
                                && newPasswordCtrl.errors.PasswordRestriction
                                && (newPasswordCtrl.dirty || newPasswordCtrl.touched)"> Your new password must satisfy all of the
            conditions. </mat-error>
        </mat-form-field>
        <mat-form-field class="account-setting-input"
                        color="accent">
          <input matInput
                 id="confirm-new-password"
                 placeholder="Confirm new password"
                 [type]="showPasswords ? 'text' : 'password'"
                 formControlName="confirmNewPassword"
                 [formGroup]="changePasswordForm">
          <mat-icon matSuffix
                    class="good-password-color password-validity-icon"
                    *ngIf="confirmNewPasswordCtrl.valid && changePasswordForm.value.currentPassword !== changePasswordForm.value.newPassword">done </mat-icon>
          <mat-error
                     *ngIf="confirmNewPasswordCtrl.errors && confirmNewPasswordCtrl.errors.MatchPassword && (confirmNewPasswordCtrl.dirty || confirmNewPasswordCtrl.touched)">
            This field must match the new password.</mat-error>
        </mat-form-field>
        <div class="show-passwords-container">
          <a class="show-passwords-link"
             (click)="showPasswordsToggle()">
            <mat-icon class="show-passwords-icon secondary-text-color"> visibility</mat-icon>
            <span class="show-passwords-text"
                  [innerHTML]="showPasswords ? 'Hide passwords' : 'Show passwords'"></span>
          </a>
        </div>
        <div class="change-password-buttons">
          <button mat-raised-button
                  type="submit"
                  color="accent"
                  (click)="savePassword()"
                  [formGroup]="changePasswordForm"
                  [disabled]="changePasswordForm.invalid || federatedUser || changePasswordForm.value.currentPassword == changePasswordForm.value.newPassword">Change password</button>
          <button mat-button
                  class="cancel"
                  type="submit"
                  (click)="cancelPassword()"
                  [formGroup]="changePasswordForm"
                  [disabled]="!changePasswordForm.dirty && !showPasswords">Cancel</button>
        </div>
      </form>
    </div>
    <div class="password-restrictions">
      <p class="password-restrictions-title">Password requirements:</p>
      <ul class="password-restrictions-list">
        <li>At least 8 characters</li>
        <li>At least 1 lowercase letter (a-z)</li>
        <li>At least 1 uppercase letter (A-Z)</li>
        <li>At least 1 number (0-9)</li>
        <li>Must not be any part of your username</li>
        <li>Cannot be any of your last 4 passwords</li>
      </ul>
    </div>
  </div>
</div>
