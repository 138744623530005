import { Subscription } from "rxjs";

import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";

import { environment } from "./../../../../environments/environment";
import { BlockerService } from "./../../../services/blocker.service";
import { UserInfoService } from "./../../../services/user-info.service";
import { SSOService } from "../../../services/sso.service";

@Component({
  selector: "main-nav",
  templateUrl: "./main-nav.component.html",
  styleUrls: ["./main-nav.component.scss"]
})
export class MainNavComponent implements OnInit, OnDestroy {
  userName: string;
  firstName: string;
  givenName: string;
  IsFederatedAccountUser: boolean;
  localEnvironment: any;

  isLoggedIn = false;
  isMenuOpen: boolean = false;
  ssoLoginEndpoint: string;
  ssoLogoutEndpoint: string;

  private subscriptions = new Subscription();

  constructor(
    private router: Router,
    private userInfoService: UserInfoService,
    private blockerService: BlockerService,
    private ssoService: SSOService
  ) {
    this.localEnvironment = environment;
  }

  ngOnInit() {
    this.subscribeToUserNames();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  initiateLogin() {
    if (window.location.href.indexOf("benchmark") !== -1) {
      this.router.navigate(["./federatedlogin"], {
        queryParams: { referrerurl: window.location.href },
      });
    } else {
      //If no benchmark route, after login land on my applications page
      let reffUrl = window.location.origin + "/applications";
      this.router.navigate(["./federatedlogin"], {
        queryParams: { referrerurl: reffUrl },
      });
    }
  }

  getAppConfigurations() {
    if (this.userInfoService.validateAuthToken()) {
      this.ssoService.getAppConfigs().subscribe((res) => {
        if (res) {
          this.generateAppObject(res.applications);
        }
      });
    }
  }

  generateAppObject(arg) {
    const entitlementAry = [];
    arg.forEach((application) => {
      let obj = {
        name: "",
        displayName: "",
        documentationUrl: "",
        productImage: "",
        environments: [],
        selectedEnv: "",
        selectedEnvWebLink: ""
      };
      if (application.environments.length > 0) {
        obj.name = application.ui_configs.name;
        obj.displayName = application.ui_configs.displayName;
        obj.productImage = application.ui_configs.productImage;
        obj.documentationUrl = application.ui_configs.documentationUrl;
        obj.environments = application.environments;
        obj.selectedEnv = application.environments[0].env_name;
        obj.selectedEnvWebLink = application.environments[0].web_link;
        entitlementAry.push(obj);
      }
    });
    this.prepareDataToDrawer(entitlementAry);
  }

  prepareDataToDrawer(arg) {
    const arrayToDrawer = [];
    arg.forEach(element => {
      if (element.environments.length > 1) {
        element.environments.forEach(env => {
          let obj = {
            name: element.displayName,
            subtitle: env.env_name, 
            iconUrl: environment.appConfig.staticContent + '/product-icons/' + element.productImage,
            url: null
          }
          obj.url = env.web_link;
          arrayToDrawer.push(obj);
        });
      } else {
        let obj = {
          name: element.displayName,
          iconUrl: environment.appConfig.staticContent + '/product-icons/' + element.productImage,
          url: element.environments[0].web_link
        }
        arrayToDrawer.push(obj);
      }
    });
    const bankingPortalProducts = {
      order: 1,
      url: environment.appConfig.bankingPortalEndpoint.url,
      name: 'Banking Portal',
      initials: 'BP',
      tooltip: 'My banking applications',
      iconUrl: null,
      isParent: true,
      children: arrayToDrawer
    };
    const el = document.querySelector('gateway-drawer');
      el.setAttribute('customproducts', JSON.stringify([bankingPortalProducts]));
      el.setAttribute('userid', this.userInfoService._decodedToken.email);
  }

  initiateLogout() {
    this.router.navigate(["./logout"], {
      queryParams: { redirectUrl: window.location.origin },
    });
  }

  public onMenuClick(): void {
    this.isMenuOpen = false;
  }

  navigateToMA() {
    window.open("https://www.moodysanalytics.com/");
  }

  triggerFalseClick(event: any) {
    event.preventDefault();
    event.target.click();
  }

  private subscribeToUserNames() {
    this.subscriptions.add(
      this.userInfoService.authToken$.subscribe(
        ({ authToken, decodedToken }) => {
          this.isLoggedIn = !!authToken;

          this.userName = this.userInfoService.getUserName();
          this.firstName = this.userInfoService.getFirstName();
          this.givenName = this.userInfoService.getGivenName();
          this.IsFederatedAccountUser =
            this.userInfoService.getUserFederatedAccountInfo();

          this.ssoLoginEndpoint =
            environment.appConfig.ssoServiceEndpoint + "/auth/login";
          this.ssoLogoutEndpoint =
            environment.appConfig.ssoServiceEndpoint +
            "/auth/logout/" +
            (authToken || "");
          this.getAppConfigurations();
        }
      )
    );
  }
}
