<div class="applications-container">
    <div class="my-applications">
        <app-myapplicationslist [list]="entitlementAry"
                                (notifyParent)="goToApp($event)"
                                (notifyParentDocUrl)="goToDocumentation($event)"
                                (notifyParentContactus)="goToContactus()"></app-myapplicationslist>
    </div>
    <div class="additional-resources">
        <app-additionalresources [list]="additionalResources"></app-additionalresources>
    </div>
</div>
<div class="product-links">
    <app-product-links></app-product-links>
</div>
