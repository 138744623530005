import { tap, catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError as ObservableThrowError, empty as ObservableEmpty, of } from 'rxjs';
import { NotificationService } from '../services/notifications.service';
import { environment } from '../../environments/environment';
import { UserInfoService } from './user-info.service';
import { BlockerService } from './blocker.service';
import { IsLoadingService } from '@service-work/is-loading';
@Injectable()
export class SSOService {
  private ssoServiceEndpoint: string;
  private userCodeEndpoint: string;
  private authTokenEndpoint: string;
  private renewTokenEndpoint: string;
  private changePasswordEndpoint: string;
  private ssoTokenEndpoint: string;
  private deleteTokenEndpoint: string;
  appConfigEndpoint: string;
  public refreshRef;

  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private userInfoService: UserInfoService,
    private blockerService: BlockerService,
    private isLoadingService: IsLoadingService,
    private notification: NotificationService
  ) {
    this.ssoServiceEndpoint = environment.appConfig.ssoServiceEndpoint;
    this.userCodeEndpoint = '/auth/login';
    this.authTokenEndpoint = '/auth/gettoken/';
    this.renewTokenEndpoint = '/auth/renewtoken';
    this.ssoTokenEndpoint = '/auth/ssotoken';
    this.changePasswordEndpoint = '/user/changepassword';
    this.deleteTokenEndpoint = '/v1/token';
    this.appConfigEndpoint = '/v2/apps';
  }

  /*Redirect to WSO2 Login to get user code*/
  public getUserCode(ssoVersion?: number, queryParamfdid?: string) {
    if (queryParamfdid) {
      window.location.href = this.ssoServiceEndpoint + '/v1/' + queryParamfdid + '/login';
    } else {
      window.location.href = this.ssoServiceEndpoint + this.versionEndpoint(ssoVersion, this.userCodeEndpoint);
    }
  }

  /*Get SSO Authorization Token*/
  public getAuthTokenHeaders(userCode: string, fdid: string, ssoVersion?: number): Observable<HttpHeaders> {
    const url = this.ssoServiceEndpoint + this.versionEndpoint(ssoVersion, this.authTokenEndpoint);
    const fdidString = fdid && fdid.length > 0 ? '?fdid=' + fdid : '';
    // responseType set to 'text' since the blank body in the response causes an error on IE
    return this.httpClient.get(url + userCode + fdidString, { observe: 'response', responseType: 'text', withCredentials: true }).pipe(
      map(response => response.headers),
      catchError(this.handleError)
    );
  }

  public renewAuthToken(latestValidToken): Observable<string> {
    const authToken = latestValidToken;
    if (authToken) {
      const url = this.ssoServiceEndpoint + this.renewTokenEndpoint;
      // responseType set to 'text' since the blank body in the response causes an error on IE
      return this.httpClient
        .get(url, {
          observe: 'response',
          responseType: 'text',
          headers: new HttpHeaders().set('Authorization', authToken),
          withCredentials: true
        })
        .pipe(
          map(response => response.headers.get('Authorization')),
          tap((res) => {
          }),
          catchError(error => {
            if (error.status !== 401) {
              this.notification.showError(error.message, error.status);
            }
            return of(error);
          })
        );
    }
  }

  public ssoToken() {
    this.isLoadingService.add();
    const url = this.ssoServiceEndpoint + this.ssoTokenEndpoint;
    return this.httpClient
      .get(url, {
        observe: 'response',
        responseType: 'text',
        withCredentials: true
      })
      .pipe(
        map(response => response.headers.get('Authorization')),
        tap((res) => {
          this.isLoadingService.remove();
        }),
        catchError((err) => {
          // if (err.status >= 500) {
          //   alert(`
          //   The Moody's authentication system is undergoing maintenance.
          //   Upon completion of this work, normal performance 
          //   will be restored. Please try signing in again soon.
          //   For additional support, contact MA_support@moodys.com
          // `);
          // }
          if (err.status !== 401) {
            this.notification.showError(err.message, err.status);
          }
          this.userInfoService.authTokenSubject$.next({
            authToken: undefined,
            decodedToken: '',
          });
          this.userInfoService.clearUserInfo();
          this.isLoadingService.remove();
          return of(err);
        })
      );
  }

  public deleteToken(redirectUrl?: string) {
    // const authToken = this.userInfoService.getAuthToken();
    // const authTokenValid = this.userInfoService.validateAuthToken();//Validate if token is still valid
    // if (authToken && authTokenValid) {
    const url = this.ssoServiceEndpoint + this.deleteTokenEndpoint;
    return this.httpClient
      .delete(url, {
        observe: 'response',
        responseType: 'text',
        // headers: new HttpHeaders().set('Authorization', authToken),
        withCredentials: true,
        params: redirectUrl ? new HttpParams().set('redirectUrl', redirectUrl) : {}
      })
      .pipe(
        map(response => response.body),
        catchError(this.handleError)
      );
  }

  public changePassword(changePasswordForm): Observable<any> {
    const url = this.ssoServiceEndpoint + this.changePasswordEndpoint;

    const isTokenValid = this.userInfoService.validateAuthToken();

    if (isTokenValid) {
      const authToken = this.userInfoService.getAuthToken();

      return this.httpClient
        .put(
          url,
          {
            currentPassword: changePasswordForm.currentPassword,
            newPassword: changePasswordForm.newPassword
          },
          {
            observe: 'response',
            responseType: 'text',
            headers: new HttpHeaders().set('Authorization', authToken)
          }
        )
        .pipe(catchError(this.handleError));
    } else {
      this.ssoToken().subscribe((res) => {
        this.renewAuthToken(res).subscribe(newToken => {
          if (newToken) {
            this.userInfoService.setAuthToken(newToken);
            return this.changePassword(changePasswordForm);
          } else {
            if (this.userInfoService.isMAToken()) {
              this.router.navigate(['./federatedlogin']);
            } else {
              // if there is no new auth token returned then take user to the login page
              this.router.navigate(['./login']);
            }
          }
        });
      })
    }
  }

  /**************************************
        UTILITIES
    **************************************/

  private handleError(error: any): Observable<any> {
    if (this.blockerService) {
      this.blockerService.hide();
    }
    return ObservableThrowError(error.message || error);
  }

  // Utility function to add a version to the endpoint, if necessary
  private versionEndpoint(ssoVersion: number, endpoint: string): string {
    if (ssoVersion && ssoVersion > 1) {
      return endpoint.replace('/auth/', '/auth/ma/');
    } else {
      return endpoint;
    }
  }

  public getAppConfigs() {
    this.isLoadingService.add();
    let url = environment.appConfig.multiEnvEndpoint + '/v2/infra/environment';
    const authToken = this.userInfoService.getAuthToken();
    return this.httpClient.get(
      url, {
      headers: new HttpHeaders().set('Authorization', authToken)
    }).pipe(
      tap((res) => {
        this.isLoadingService.remove();
      }),
      catchError((err) => {
        this.isLoadingService.remove();
        if (err.status === 401) {
          this.userInfoService.clearUserInfo();
          this.router.navigate(['./federatedlogin']);
        } else {
          this.notification.showError(err.error.detail, err.status);
          this.router.navigate(['./']);
        }
        return of(err);
      })
    );
  }

}