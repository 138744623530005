<!-- <div class="main-content-container"
     [ngStyle]="{'padding-top.px': totalNavHeight > 146 ? 100 : totalNavHeight, 'min-height.px': screenHeight - totalNavHeight}"
     (window:resize)="onResize()"> -->
<link href="https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Round&display=block"
      rel="stylesheet">
<link href="https://fonts.googleapis.com/css?family=Roboto:300,400,500"
      rel="stylesheet">
<link rel="stylesheet"
      href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200" />
<div *ngIf="isBlockerEnabled | async"
     class="loading-container">
  <div class="loading-overlay">
    <mat-spinner></mat-spinner>
  </div>
</div>
<header>
  <div><main-nav *ngIf="showFullScreen == false"></main-nav>
  </div>
</header>
<div class="main-content-container">
  <main [@routeAnimation]="prepRouteState(routerOutlet)">
    <router-outlet #routerOutlet="outlet"
                   style="position:absolute;"></router-outlet>
  </main>
  <div class="back-to-top"
       *ngIf="!onBenchmarkPage">
    <a (click)="onScrollToTop()">Back to Top</a>
  </div>
  <footer [ngClass]="{'benchmark-footer' : onBenchmarkPage}">
  </footer>
</div>
