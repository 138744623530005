import { Component, Input, OnInit } from '@angular/core';
@Component({
  selector: 'app-additionalresources',
  templateUrl: './additionalresources.component.html',
  styleUrls: ['./additionalresources.component.scss']
})
export class AdditionalresourcesComponent implements OnInit {
  @Input() list:any[] =[];
  constructor() { }

  ngOnInit(): void {}
}
