import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

declare global {
  interface Window {
    _walkmeConfig: any;
  }
}

let _walkmeConfig = window._walkmeConfig; // ok now

if (environment.appConfig.walkMeUrl) {
  const walkme = document.createElement('script');
  walkme.type = 'text/javascript';
  walkme.async = true;
  walkme.src = environment.appConfig.walkMeUrl;
  window._walkmeConfig = { smartLoad: true };
  // Add an append script here depends on where you want to put it(header or body)
  document.head.appendChild(walkme)
}


platformBrowserDynamic().bootstrapModule(AppModule);
