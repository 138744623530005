import { Component, OnInit, Input } from '@angular/core';

import { environment } from "../../../../../../environments/environment";

@Component({
  selector: 'app-additionalresourcecard',
  templateUrl: './additionalresourcecard.component.html',
  styleUrls: ['./additionalresourcecard.component.scss']
})
export class AdditionalresourcecardComponent implements OnInit {
@Input() list; 
localEnvironment;
  constructor() {  this.localEnvironment = environment;
  }

  ngOnInit(): void {
  }

}
