/*Angular*/
import { Injectable, Inject } from '@angular/core';

@Injectable()
export class AnimationTriggerService {

    constructor() {
    }

    // trigger the element's animation if it is within the view on initial load (before scrolling library is triggered)
    public triggerElementAnimations(curComponent: any) {

        if (curComponent.checkAnimationTriggerStatus()) {
            const elements = document.getElementsByClassName('product-animated-content-container');
            // an object with attribute status set to true so it acts as if in-view event was fired
            const event = {status: true};
            // height of the current window
            const windowBottomPos = window.innerHeight;
            // the last product section that was animated's height
            let lastAnimatedProductSectionHeight = -1;

            // iterate through all the product content elements,
            // triggering their animations if within the current window size
            for (let i = 0; i < elements.length; i++) {
              const curElement = elements[i];
              const curElementTopPos = curElement.getBoundingClientRect().top;
              const curElementHeight = curElement.getBoundingClientRect().height;

              // variable for holding the height of the previous element to check if it's new height is being used
              // to calculate if the current element is still in the window
              let prevElementHeight = curElementHeight;

              if (i > 0) {
                prevElementHeight = elements[i - 1].getBoundingClientRect().height;
              }

              if (prevElementHeight > lastAnimatedProductSectionHeight) {
                if (curComponent[curElement.id]) {
                  if ((curComponent[curElement.id].length === 0) && (curElementTopPos < windowBottomPos)) {
                    // call the animation trigger function by concatenating 'Trigger' at the end of the id
                    if (curComponent[curElement.id + 'Trigger']) {
                      curComponent[curElement.id + 'Trigger'](event);
                      // set the last animated product section's height to the current elements height
                      lastAnimatedProductSectionHeight = curElementHeight;
                    } else {
                      break;
                    }
                  }
                }
              }
            }
        }
    }
}
