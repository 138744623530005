import { environment } from './../../../../environments/environment';
import { ApplicationRef, Component, OnInit } from "@angular/core";
import {
  ActivatedRoute,
  Router,
} from "../../../../../node_modules/@angular/router";
import { SSOService } from "../../../services/sso.service";
import { UserInfoService } from "../../../services/user-info.service";
import { BlockerService } from "../../../services/blocker.service";
import { AbstractLoginDirective } from "./abstract-login.directive";
import { ProjectWorkspaceService } from "../../../services/project-workspace.service";
import { ToastrService } from "ngx-toastr";
import { NotificationService } from "../../../services/notifications.service";

@Component({
  selector: "login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent extends AbstractLoginDirective {
  localEnvironment: any;
  constructor(
    activatedRoute: ActivatedRoute,
    router: Router,
    ssoService: SSOService,
    userInfoService: UserInfoService,
    blockerService: BlockerService,
    projectWorkspaceService: ProjectWorkspaceService,
    toastr: ToastrService,
    notification: NotificationService,
    appRef: ApplicationRef
  ) {
    super(
      activatedRoute,
      router,
      ssoService,
      userInfoService,
      blockerService,
      projectWorkspaceService,
      toastr,
      notification,
      appRef
    );
    this.ssoEndpointVersion = 1;
    this.localEnvironment = environment;
  }
}
