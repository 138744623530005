import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { environment } from "../../../../environments/environment";

@Component({
  selector: "app-product-links",
  templateUrl: "./product-links.component.html",
  styleUrls: ["./product-links.component.scss"],
})
export class ProductLinksComponent implements OnInit {
  productLinksUrl: string;
  productLinks: any;

  constructor(private http: HttpClient) {
    this.productLinksUrl = environment.appConfig.staticContent + '/content/product-links.json';
  }

  ngOnInit(): void {
    this.http.get(this.productLinksUrl).subscribe((res) => {
      this.productLinks = res;
    });
  }
}
