import { Injectable } from '@angular/core';

import { ToastrService } from 'ngx-toastr';
declare var heap: any;

@Injectable()
export class NotificationService {
    constructor(private toastr: ToastrService) { }
    // Toast Notification Functions
    showSuccess(message: string, title: string) {
        this.toastr.success(message, title);
    }

    showError(message: string, title: string) {
        heap.track("custom_event: error message displayed");
        this.toastr.error(message, title, {
            timeOut: 8000
        });
    }

    showWarning(message: string, title: string) {
        this.toastr.warning(message, title);
    }

    showInfo(message: string, title) {
        this.toastr.info(message, title);
    }

    showCustom(title: string, message: string) {
        this.toastr.success(message, title,
            {
                enableHtml: true,
                closeButton: true,
                disableTimeOut: true,
                positionClass: 'toast-bottom-right'
            });
    }

    clearNotifications() {
        this.toastr.clear();
    }
}
