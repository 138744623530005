import { AbstractControl } from '@angular/forms';

export class NewPasswordValidator {
  static MatchPassword(AC: AbstractControl) {
    const newPassword = AC.get('newPassword').value; // to get value in input tag
    const confirmNewPassword = AC.get('confirmNewPassword').value; // to get value in input tag
    if (newPassword !== confirmNewPassword) {
      AC.get('confirmNewPassword').setErrors({ MatchPassword: true });
    } else {
      return null;
    }
  }

  static CheckPasswordRestrictions(AC: AbstractControl) {
    const newPassword = AC.get('newPassword').value; // to get value in input tag
    const passwordRestrictionRegex = new RegExp('^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$');
    if (passwordRestrictionRegex.test(newPassword) && newPassword !== AC.get('userName').value) {
      return null;
    } else {
      AC.get('newPassword').setErrors({ PasswordRestriction: true });
    }
  }
}
