import { IAppConfig } from './../../config/IAppConfig';
import * as _ from 'lodash';

export class Utility {
  static allowedDomain: Array<string> = ['moodysanalytics.com', 'moodysanalytics.net', 'analytics.moodys.net', 'moodyskmv.com'];

  static getEndpointVersion(appConfig: IAppConfig, appEndpoint: string): number {
    if (!appEndpoint) {
      return undefined;
    } else {
      // Get config for product
      const productEndpoint: any = _.find(appConfig, (value: any) => {
        if (value.url) {
          return _.startsWith(appEndpoint, value.url);
        } else {
          return false;
        }
      });

      if (productEndpoint && productEndpoint.ssoEndpointVersion && productEndpoint.ssoEndpointVersion !== null) {
        return productEndpoint.ssoEndpointVersion;
      }
      return undefined;
    }
  }

  static extractHostname(url: string): string {
    let hostname = '';
    // find & remove protocol (http, ftp, etc.) and get hostname
    if (url) {
      hostname = url.indexOf('//') > -1 ? url.split('/')[2] : url.split('/')[0];
      // find & remove port number
      hostname = hostname.split(':')[0];
      // find & remove "?"
      hostname = hostname.split('?')[0];
    }
    return hostname;
  }

  static isRegisteredProductUrl(referrerurl: string): boolean {
    return Utility.allowedDomain.some(regUrl => referrerurl.endsWith(regUrl));
  }

  static getProductEndpoint(appConfig: any, referrerUrl: string): string {
    let productEndpoint: any;
    productEndpoint = _.find(appConfig, function (value, key) {
      if (value.url) {
        return _.startsWith(referrerUrl, value.url);
      } else {
        return false;
      }
    });
    let productEndpointUrl = '';
    if (productEndpoint) {
      productEndpointUrl = productEndpoint.url;
    }
    return productEndpointUrl;
  }

  static getUrlProductName(appConfig: any, referrerUrl: string): string {
    let productEndpoint: any;
    productEndpoint = _.find(appConfig, function (value, key) {
      if (value.url) {
        return _.startsWith(referrerUrl, value.url);
      } else {
        return false;
      }
    });
    let productEndpointName = '';
    if (productEndpoint) {
      productEndpointName = productEndpoint.name;
    }
    return productEndpointName;
  }
}
