<mat-toolbar>
   <mat-toolbar-row class="main-nav"
                    color="primary">
      <div class="mobile-menu-button">
         <button mat-icon-button
                 (click)="mobileNav.toggle()">
            <!-- <i class="fa-light"
           [ngClass]="isMenuOpen ? 'fa-xmark' : 'fa-bars'"></i> -->
            <mat-icon class="material-symbols-rounded">{{ isMenuOpen ? 'close' : 'menu' }}</mat-icon>
         </button>
      </div>
      <div class="logo-container">
         <div class="ma-logo"><a href="https://www.moodysanalytics.com"
               target="_blank"><img src="{{localEnvironment.appConfig.staticContent}}/logos/MoodysLogoWhite-2024.svg"></a></div>
         <div class="logo-divider"></div>
         <a class="bp-logo"
            routerLink="/">
            <div class="bp-logo-img"><img src="{{localEnvironment.appConfig.staticContent}}/logos/Banking_Portal_Duo.svg"></div>
            <div class="bp-logo-text">Banking Portal</div>
         </a>
      </div>
      <ul class="tab-container">
         <li *ngIf="isLoggedIn"
             class="nav-link"><a routerLink="solutions"
               routerLinkActive="active"
               [routerLinkActiveOptions]={exact:true}>Solutions</a></li>
         <li class="nav-link"><a routerLink="benchmark"
               routerLinkActive="active">Insights</a></li>
         <li class="nav-link"><a href="https://www.moodysanalytics.com/about-learning-solutions"
               target="_blank">Learning</a></li>
      </ul>
      <div *ngIf="!isLoggedIn; else userMenuButton"
           class="button-container">
         <a class="mat-stroked-button main-nav-button button-contact"
            href="https://www.moodysanalytics.com/contact-us"
            target="_blank"><span style="padding: 0px 16px 0px 16px">Contact us</span></a>
         <button mat-flat-button
                 *ngIf="!isLoggedIn; else loggedIn"
                 class="main-nav-button button-login"
                 (click)="initiateLogin()"><span style="padding: 0px 16px 0px 16px">Log in</span></button>
      </div>
      <ng-template #userMenuButton>
         <div class="user-menu-container">
            <button [matMenuTriggerFor]="userMenu">
               <mat-icon>person</mat-icon>
               <!-- <i class="fa-regular fa-user"></i> -->
               <div class="user-name"> {{ firstName ? firstName : userName }} </div>
               <mat-icon>expand_more</mat-icon>
               <!-- <i class="fa-regular fa-angle-down"></i> -->
            </button>
            <mat-menu #userMenu
                      class="user-menu"
                      yPosition="below"
                      xPosition="before"
                      [overlapTrigger]="false">
               <a mat-menu-item
                  id="mobile-user-settings"
                  routerLink="./account">Change password</a>
               <a mat-menu-item
                  id="mobile-contactus-dropdown"
                  href="http://www.moodysanalytics.com/Contact-Us"
                  target="_blank">Contact us</a>
               <a mat-menu-item
                  id="mobile-logout"
                  (keydown.enter)="triggerFalseClick($event)"
                  (click)="initiateLogout()">Log out</a>
            </mat-menu>
         </div>
         <gateway-drawer customProductsTitle="Banking"
                         iconColor="#FFF"></gateway-drawer>
      </ng-template>
   </mat-toolbar-row>
</mat-toolbar>
<mat-sidenav #mobileNav
             class="mobile-menu"
             [(opened)]="isMenuOpen"
             (click)="onMenuClick()">
   <div class="link-container">
      <a routerLink="/"
         routerLinkActive="active"
         [routerLinkActiveOptions]={exact:true}>Banking Portal</a>
      <a *ngIf="isLoggedIn"
         routerLink="solutions"
         routerLinkActive="active">Solutions</a>
      <a routerLink="benchmark"
         routerLinkActive="active">Insights</a>
      <a href="https://www.moodysanalytics.com/about-learning-solutions"
         target="_blank">Learning</a>
      <div class="mobile-menu-divider"></div>
      <a *ngIf="isLoggedIn"
         routerLink="./account"
         routerLinkActive="active">Change password</a>
      <a href="https://www.moodysanalytics.com/contact-us"
         target="_blank">Contact us</a>
      <a *ngIf="!isLoggedIn; else logOutButton"
         (click)="initiateLogin()"
         routerLinkActive="active">Log in</a>
      <ng-template #logOutButton>
         <a (keydown.enter)="triggerFalseClick($event)"
            (click)="initiateLogout()"
            routerLinkActive="active">Log out</a>
      </ng-template>
   </div>
</mat-sidenav>
