import { Component, OnInit } from "@angular/core";

@Component({
  selector: "not-found",
  templateUrl: "./not-found.component.html",
  styleUrls: ["./not-found.component.scss"],
})
export class NotFoundComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  goToMA() {
    window.open("https://www.moodysanalytics.com/");
  }
  contactUs() {
    window.open("http://www.moodysanalytics.com/Contact-Us");
  }
}
