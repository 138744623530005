import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Rx';
import { map, catchError } from 'rxjs/operators';
import { BlockerService } from './blocker.service';
import { throwError as ObservableThrowError, empty as ObservableEmpty } from 'rxjs';
import { UserInfoService } from './user-info.service';
import { Router } from '@angular/router';

@Injectable()
export class ProjectWorkspaceService {
  private protectedPaths = [
    '/login',
    '/logout',
    '/account',
    '/federatedlogin',
    '/federatedlogout',
    '/adminportal',
    '/adminportal/user-sync',
    '/adminportal/user-mgmt',
    '/adminportal/assign-userroles',
    '/assign-entitlements'
  ];

  constructor(
    private httpClient: HttpClient, 
    private blockerService: BlockerService, 
    public userInfoService: UserInfoService, 
    public router: Router
    ) {
  }

  // Changes the URL path to the selected product's tab
  setUrlFromTab(currentTabIndex) {
    // If not a login/logout path
    // set the selected product index in local storage to reflect on the product specified in the URL path
    if (currentTabIndex === 1) {
      history.replaceState({}, '', window.location.protocol + '//' + window.location.host + '/' + 'riskcalc');
    } else if (currentTabIndex === 2) {
      history.replaceState({}, '', window.location.protocol + '//' + window.location.host + '/' + 'cmm');
    } else if (currentTabIndex === 3) {
      history.replaceState({}, '', window.location.protocol + '//' + window.location.host + '/' + 'riskbench');
    } else if (currentTabIndex === 4) {
      history.replaceState({}, '', window.location.protocol + '//' + window.location.host + '/' + 'cap');
    } else if (currentTabIndex === 5) {
      history.replaceState({}, '', window.location.protocol + '//' + window.location.host + '/' + 'impairmentstudio');
    } else if (currentTabIndex === 6) {
      history.replaceState({}, '', window.location.protocol + '//' + window.location.host + '/' + 'impairmentcalc');
    } else if (currentTabIndex === 7) {
      history.replaceState({}, '', window.location.protocol + '//' + window.location.host + '/' + 'cra');
    } else if (currentTabIndex === 8) {
      history.replaceState({}, '', window.location.protocol + '//' + window.location.host + '/' + 'riskfrontier');
    } else if (currentTabIndex === 9) {
      history.replaceState({}, '', window.location.protocol + '//' + window.location.host + '/' + 'creditedge');
    } else if (currentTabIndex === 10) {
      history.replaceState({}, '', window.location.protocol + '//' + window.location.host + '/' + 'applications');
    } else if (currentTabIndex === 11) {
      history.replaceState({}, '', window.location.protocol + '//' + window.location.host + '/' + 'portfoliostudio');
    } else if (currentTabIndex === 13) {
      history.replaceState({}, '', window.location.protocol + '//' + window.location.host + '/' + 'onlinebudgeting');
    } else if (currentTabIndex === 14) {
      history.replaceState({}, '', window.location.protocol + '//' + window.location.host + '/' + 'onlinececl');
    } else if (currentTabIndex === 15) {
      history.replaceState({}, '', window.location.protocol + '//' + window.location.host + '/' + 'alm');
    } else if (currentTabIndex === 16) {
      history.replaceState({}, '', window.location.protocol + '//' + window.location.host + '/' + 'rpa');
    } else if (currentTabIndex === 17) {
      history.replaceState({}, '', window.location.protocol + '//' + window.location.host + '/' + 'nextgen-pd');
    }
  }

  navigateToProductPageByIndex(currentTabIndex) {
    // set the selected product index in local storage to reflect on the product specified in the URL path
    if (currentTabIndex === 1) {
      this.router.navigate(['./riskcalc']);
    } else if (currentTabIndex === 2) {
      this.router.navigate(['./cmm']);
    } else if (currentTabIndex === 3) {
      this.router.navigate(['./riskbench']);
    } else if (currentTabIndex === 4) {
      this.router.navigate(['./cap']);
    } else if (currentTabIndex === 5) {
      this.router.navigate(['./impairmentstudio']);
    } else if (currentTabIndex === 6) {
      this.router.navigate(['./impairmentcalc']);
    } else if (currentTabIndex === 7) {
      this.router.navigate(['./cra']);
    } else if (currentTabIndex === 8) {
      this.router.navigate(['./riskfrontier']);
    } else if (currentTabIndex === 9) {
      this.router.navigate(['./creditedge']);
    } else if (currentTabIndex === 11) {
      this.router.navigate(['./portfoliostudio']);
    } else if (currentTabIndex === 13) {
      this.router.navigate(['./onlinebudgeting']);
    } else if (currentTabIndex === 12) {
      this.router.navigate(['./bankingcloud']);
    } else if (currentTabIndex === 14) {
      this.router.navigate(['./onlinececl']);
    } else if (currentTabIndex === 15) {
      this.router.navigate(['./alm']);
    } else if (currentTabIndex === 16) {
      this.router.navigate(['./rpa']);
    }
  }

  /**************************************
        UTILITIES
    **************************************/

  private handleError(error: any): Observable<any> {
    if (this.blockerService) {
      this.blockerService.hide();
    }

    return ObservableThrowError(error.message || error);
  }
}
