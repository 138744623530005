export const landingData = {
  jumbotron: {
    title: `Welcome to the<br />Banking Portal`,
    notLoggedInTitle: `Log in to access your applications.`,
    loggedInTitle: `You are logged in as `,
    bgVideo: ``,
  },
  exploreSolutions: {
    title: `Explore our solutions`,
    productSections: [
      {
        sectionTitle: `Lending`,
        categories: [
          {
            categoryTitle: `Targeting & onboarding`,
            products: [
              {
                name: ``,
                url: ``,
              },
              {
                name: ``,
                url: ``,
              },
            ],
          },
          {
            categoryTitle: `Credit assessment`,
            products: [
              {
                name: ``,
                url: ``,
              },
              {
                name: ``,
                url: ``,
              },
            ],
          },
          {
            categoryTitle: ``,
            products: [
              {
                name: ``,
                url: ``,
              },
              {
                name: ``,
                url: ``,
              },
            ],
          },
          {
            categoryTitle: ``,
            products: [
              {
                name: ``,
                url: ``,
              },
              {
                name: ``,
                url: ``,
              },
            ],
          },
        ],
      },
      {
        sectionTitle: `Risk`,
      },
      {
        sectionTitle: `Finance`,
      },
      {
        sectionTitle: `Data & analytics`,
      },
    ],
  },
};
