import { Component, OnInit } from "@angular/core";
import { Location } from "@angular/common";
import { Router, NavigationEnd } from "@angular/router";
import { filter } from "rxjs/operators";

import { environment } from "./../../../../environments/environment";
import { UserInfoService } from "./../../../services/user-info.service";
// import * as internal from 'stream';

@Component({
  selector: "footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnInit {
  currentRoute: string;
  isLandingPage = false;
  isLoggedIn = false;
  ssoLoginEndpoint: string;
  currentYear;
  localEnvironment: any;

  constructor(
    private userInfoService: UserInfoService,
    private router: Router,
    private location: Location
  ) {
    this.currentYear = new Date().getFullYear();
    this.localEnvironment = environment;

    this.ssoLoginEndpoint =
      this.localEnvironment.appConfig.ssoServiceEndpoint + "/auth/login";
  }

  ngOnInit() {
    this.router.events
      .pipe(filter((e) => e instanceof NavigationEnd))
      .subscribe((navEnd: NavigationEnd) => {
        this.currentRoute = navEnd.urlAfterRedirects;
        this.isLandingPage = this.currentRoute == "/";
      });
  }
}
