<div class="container">
    <div class="block-container"
         *ngFor="let item of list">
        <div class="item-container">
            <div class="image-block"> <img src="{{localEnvironment.appConfig.staticContent}}/product-icons/{{item.productImage}}"
                     alt=""></div>
            <div class="text-block">
                <div class="display-name"><a target="_self"
                       href="{{item.displayNameUrl}}">{{item.displayName}}</a></div>
                <div class="description">{{item.description}}</div>
                <a target="_self"
                   class="documnetation-url"
                   href="{{item.appurl}}">{{item.appurlDisplayName}}</a>
            </div>
        </div>
    </div>
</div>
