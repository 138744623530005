<div class="logout-container">
  <div class="logout-message-container">
    <img class="logout-message-logo"
         src="{{localEnvironment.appConfig.staticContent}}/logos/MoodysLogoWhite-2024.svg" />
    <div>
      <mat-spinner class="logout-message-spinner"
                   color="accent"
                   [strokeWidth]="7"></mat-spinner>
    </div>
    <div class="logout-message-text center-text">Logging out...</div>
  </div>
</div>
<form *ngIf="url && logoutParams"
      #logoutForm
      name="logoutForm"
      method="post"
      action="{{url}}">
  <input type="hidden"
         name="id_token_hint"
         value="{{logoutParams.id_token_hint}}" />
  <input type="hidden"
         name="post_logout_redirect_uri"
         value="{{logoutParams.post_logout_redirect_uri}}">
</form>
