import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

/**
 * Blocker Service
 * Use to show/hide spinner to block page UI interactions
 */
@Injectable()
export class BlockerService {
    public enabled$ = new Subject();

    /**
     * Count of open blocker requests to keep track of show & hide requests
     */
    public count = 0;

    constructor() {
    }

    /**
     * Show a single error message
     * If there is no open blocker, push true (to show the first blocker).
     */
    public show() {
        // check for empty count and increment after the conditional

        // if (this.count++ === 0) {
        // Count is 1 now
        this.enabled$.next(true);
        // }
    }

    /**
     * Push hide overlay status
     * If the count of open blocker requests is 1, push false (to close the last blocker).
     */
    public hide() {
        // if (this.count > 0) {
        //     this.count--;
        // }
        // if (this.count === 0) {
        this.enabled$.next(false);
        // }
    }

    /**
     * Push hide overlay status, ignore and reset all previous states
     */
    public forcedHide() {
        this.count = 0;
        this.enabled$.next(false);
    }

}
