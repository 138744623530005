<div class="my-apps">
        <div class="not-found-title">Sorry, we can't find that page.</div>
        <div class="not-found-subtitle">Let's help you find what you are looking for.</div>
        <div class="button-row">
                <button mat-raised-button
                        color="primary"
                        routerLink="/"
                        class="go-to-home">Banking Portal Home</button>
                <button mat-raised-button
                        (click)="goToMA()"
                        class="go-to-ma">Moody's Analytics.Com</button>
                <button mat-raised-button
                        class="contact-us"
                        (click)="contactUs()">Contact Us</button>
        </div>
</div>
