import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "../../../../environments/environment";
import { BlockerService } from "../../../services/blocker.service";
import { NotificationService } from "../../../services/notifications.service";
import { ProjectWorkspaceService } from "../../../services/project-workspace.service";
import { SSOService } from "../../../services/sso.service";
import { UserInfoService } from "../../../services/user-info.service";
import { Utility } from "../../../util/utility";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { HttpErrorResponse } from "@angular/common/http";

@Component({
  selector: "myapplications",
  templateUrl: "./myapplications.component.html",
  styleUrls: ["./myapplications.component.scss"],
})
export class MyapplicationsComponent implements OnInit {
  localEnvironment;
  applicationResponse: any;
  appEndpoint: string;
  entitlementAry;
  additionalResources;
  environmentName: string;

  constructor(
    public ssoService: SSOService,
    public notification: NotificationService,
    public userInfoService: UserInfoService,
    public blockerService: BlockerService,
    public router: Router,
    public projectWorkspaceService: ProjectWorkspaceService,

  ) {
    this.localEnvironment = environment;
  }

  ngOnInit(): void {
    // get latest token from SSO service 
    this.ssoService.ssoToken().subscribe((res) => {
      // check if the response is valid
      if (res && !(res instanceof HttpErrorResponse)) {
        // set the latest token
        this.userInfoService.setAuthToken(res);
        // get user's entitlements and additional ui configs
        this.getAppConfigurations();
      } else {
        this.router.navigate(['./federatedlogin']);
      }
    });

  }

  getAppConfigurations() {
    this.ssoService.getAppConfigs().subscribe((res) => {
      if (res) {
        this.applicationResponse = res;
        this.generateAppObject(this.applicationResponse.applications);
        this.generateAdditionalResources(this.applicationResponse.additionalResources);
      }
    });
  }

  generateAppObject(arg) {
    this.entitlementAry = [];
    arg.forEach((application) => {
      let obj = {
        name: "",
        displayName: "",
        documentationUrl: "",
        productImage: "",
        environments: [],
        selectedEnv: "",
        selectedEnvWebLink: ""
      };
      if (application.environments.length > 0) {
        obj.name = application.ui_configs.name;
        obj.displayName = application.ui_configs.displayName;
        obj.productImage = application.ui_configs.productImage;
        obj.documentationUrl = application.ui_configs.documentationUrl;
        obj.environments = application.environments;
        obj.selectedEnv = application.environments[0].env_name;
        obj.selectedEnvWebLink = application.environments[0].web_link;
        this.entitlementAry.push(obj);
      }
    });
  }

  generateAdditionalResources(arg) {
    this.additionalResources = [];
    arg.forEach((resource) => {
      let obj = {
        name: "",
        displayName: "",
        displayNameUrl: "",
        productImage: "",
        description: "",
        appurl: "",
        appurlDisplayName: "",
        displayOrder: -1
      };
      obj.name = resource.name;
      obj.displayName = resource.displayName;
      obj.displayNameUrl = resource.displayNameUrl ? resource.displayNameUrl : resource.url;
      obj.productImage = resource.productImage;
      obj.description = resource.description;
      obj.appurl = resource.url;
      obj.appurlDisplayName = resource.urlDisplayName ? resource.urlDisplayName : "Go to the " + resource.displayName;
      obj.displayOrder = resource.displayOrder;
      this.additionalResources.push(obj);
    });
    this.additionalResources = this.additionalResources.sort((a, b) => a.displayOrder > b.displayOrder ? 1 : -1);
  }

  goToApp(url) {
    this.appEndpoint = url;
    let authToken = this.userInfoService.getAuthToken();
    const versionFlag = Utility.getEndpointVersion(
      this.localEnvironment.appConfig,
      this.appEndpoint
    );
    // if there is no auth token then take user to login screen
    if (authToken !== null) {
      window.location.href = this.appEndpoint;
    } else {
      if (versionFlag && versionFlag > 1) {
        this.router.navigate(["./federatedlogin"], {
          queryParams: { referrerurl: window.location.href },
        });
      } else {
        this.router.navigate(["./login"], {
          queryParams: { referrerurl: this.appEndpoint },
        });
      }
    }
  }

  goToDocumentation(docurl) {
    window.open(docurl, "_blank");
  }

  goToContactus() {
    window.open(environment.appConfig.contactUsUrl);
  }

}
