import { trigger, style, transition, animate, keyframes, query, stagger, state } from '@angular/animations';

/* Animations for single elements */
export const slideBottomToTopAnimation =
    trigger('slideBottomToTopAnimation', [
        transition('* => *', [
            animate('0.60s ease-in', keyframes([
            style({opacity: 0, transform: 'translateY(50px)', offset: 0}),
            style({opacity: 0.5, transform: 'translateY(25px)', offset: 0.3}),
            style({opacity: 1, transform: 'translateY(0)', offset: 1.0}),
            ]))
        ])
    ]);

export const slideBounceBottomToTopAnimation =
    trigger('slideBounceBottomToTopAnimation', [
        transition('* => *', [
            animate('1s ease-in', keyframes([
            style({opacity: 0, transform: 'translateY(50%)', offset: 0}),
            style({opacity: 0.5, transform: 'translateY(-10px)', offset: 0.3}),
            style({opacity: 1, transform: 'translateY(0)', offset: 1.0}),
            ]))
        ])
    ]);

export const slideLeftToRightAnimation =
    trigger('slideLeftToRightAnimation', [
        transition('* => *', [
            animate('0.4s ease-in', keyframes([
            style({opacity: 0, transform: 'translateX(-20px)', offset: 0}),
            style({opacity: 0.5, transform: 'translateX(-10px)', offset: 0.3}),
            style({opacity: 1, transform: 'translateX(0)', offset: 1.0}),
            ]))
        ])
    ]);

export const popOutwardsAnimation =
    trigger('popOutwardsAnimation', [
        transition('* => *', [
            animate('0.60s ease-in', keyframes([
            style({opacity: 0, transform: 'scale(0.975)', offset: 0}),
            style({opacity: 0.5, transform: 'scale(1.025)', offset: 0.3}),
            style({opacity: 1, transform: 'scale(1)', offset: 1.0}),
            ]))
        ])
    ]);

/* Animations for multiple elements created through *ngFor */

export const multiSlideLeftToRightAnimation =
    trigger('multiSlideLeftToRightAnimation', [
        transition('* => *', [

            query(':enter', style({ opacity: 0 }), {optional: true}),

            query(':enter', stagger('300ms', [
                animate('0.5s ease-in', keyframes([
                  style({opacity: 0, transform: 'translateX(-55px)', offset: 0}),
                  style({opacity: 0.5, transform: 'translateX(-5px)',  offset: 0.3}),
                  style({opacity: 1, transform: 'translateX(0)',     offset: 1.0}),
                ]))
            ]), {optional: true})
        ])
    ]);

export const multiSlideRightToLeftAnimation =
    trigger('multiSlideRightToLeftAnimation', [
        transition('* => *', [

            query(':enter', style({ opacity: 0 }), {optional: true}),

            query(':enter', stagger('300ms', [
                animate('1.5s ease-in', keyframes([
                    style({opacity: 0, transform: 'translateX(55%)', offset: 0}),
                    style({opacity: 0.5, transform: 'translateX(5px)',  offset: 0.3}),
                    style({opacity: 1, transform: 'translateX(0)',     offset: 1.0}),
                ]))
            ]), {optional: true})
        ])
    ]);

export const multiSlideBounceBottomToTopAnimation =
    trigger('multiSlideBounceBottomToTopAnimation', [
        transition('* => *', [

            query(':enter', style({ opacity: 0 }), {optional: true}),

            query(':enter', stagger('300ms', [
                animate('1.0s ease-in', keyframes([
                    style({opacity: 0, transform: 'translateY(75%)', offset: 0}),
                    style({opacity: .5, transform: 'translateY(-10px)',  offset: 0.3}),
                    style({opacity: 1, transform: 'translateY(0)',     offset: 1.0}),
                ]))
            ]), {optional: true})
        ])
    ]);
