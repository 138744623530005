<div class="explore-solutions"
     *ngIf="productLinks">
  <div class="explore-solutions-content">
    <div class="explore-solution-title">
      <h3> Explore our solutions <a href="https://www.moodysanalytics.com/product-list/a-z-product-list"
           target="_new">View all products</a>
      </h3>
    </div>
    <div class="product-links">
      <div class="category"
           *ngFor="let category of productLinks">
        <h4>{{ category.name }}</h4>
        <div class="category-content">
          <div class="subcategory"
               *ngFor="let subcategory of category.subcategories">
            <h5>{{ subcategory.name }}</h5>
            <span *ngFor="let link of subcategory.links">
              <a href="{{ link.url }}"
                 *ngIf="link.url"
                 target="_new">{{ link.name }}</a>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="product-accordion">
      <div *ngFor="let category of productLinks">
        <h4>{{ category.name }}</h4>
        <mat-accordion multi="true">
          <mat-expansion-panel *ngFor="let subcategory of category.subcategories"
                               displayMode="flat">
            <mat-expansion-panel-header>
              <mat-panel-title>{{ subcategory.name }}</mat-panel-title>
            </mat-expansion-panel-header>
            <p *ngFor="let link of subcategory.links">
              <a href="{{ link.url }}"
                 *ngIf="link.url"
                 target="_new">{{ link.name }}</a>
            </p>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
  </div>
</div>
