<div class="login-container">
    <div class="login-message-container">
        <img class="login-message-logo"
             src="{{localEnvironment.appConfig.staticContent}}/logos/MoodysLogoWhite-2024.svg" />
        <div>
            <mat-spinner class="login-message-spinner"
                         color="accent"
                         [strokeWidth]="7"></mat-spinner>
        </div>
        <div class="login-message-text center-text">Logging in...</div>
    </div>
</div>
