import { OnInit, OnDestroy, Directive, ApplicationRef } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { environment } from './../../../../environments/environment';
import { SSOService } from './../../../services/sso.service';
import { UserInfoService } from './../../../services/user-info.service';
import { ProjectWorkspaceService } from '../../../services/project-workspace.service';
import { BlockerService } from './../../../services/blocker.service';
import { catchError } from 'rxjs/operators';
import * as _ from 'lodash';
import { HttpHeaders } from '@angular/common/http';
import { Subscription } from 'rxjs/Subscription';
import { throwError, empty as ObservableEmpty, ObservableLike } from 'rxjs';
import { Utility } from '../../../util/utility';
import { ToastrService } from 'ngx-toastr';
import { NotificationService } from '../../../services/notifications.service';

@Directive()
export class AbstractLoginDirective implements OnInit, OnDestroy {
  subscriptions: Subscription[];
  ssoEndpointVersion: number;
  productSubName: string;
  validDomains = ['moodysanalytics.com', 'moodysanalytics.net', 'analytics.moodys.net', 'moodyskmv.com'];
  isMultiorgSet = false;
  productReferrerUrlIsValid = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private ssoService: SSOService,
    private userInfoService: UserInfoService,
    private blockerService: BlockerService,
    private projectWorkspaceService: ProjectWorkspaceService,
    private toastr: ToastrService,
    public notification: NotificationService,
    private appRef: ApplicationRef,
  ) {
    this.subscriptions = [];
  }

  isDomainValid(referrerurl: string): Boolean {
    return this.validDomains.some(domain => referrerurl.endsWith(domain) || referrerurl.indexOf(domain + '/') > 1);
  }

  ngOnInit(): void {
    // hide the blocker if it is showing
    this.blockerService.hide();
    // get the user's code from the query parameter sent by server
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      const queryParamReferrerUrl = params['referrerurl'];
      const queryParamUserCode = params['code'];
      const queryParamfdid = params['fdid'];

      // if no query parameters were passed then remove the referrer url (if any) in the local storage
      if (!(queryParamReferrerUrl || queryParamUserCode)) {
        localStorage.removeItem('app-url');
      }

      // if a referrer url is provided as a query parameter
      // then add it to the local storage
      if (queryParamReferrerUrl) {
        localStorage.setItem("app-url", queryParamReferrerUrl);
      }

      // if the code is in the query parameter proceed to getting the auth token, otherwise get the code first
      if (queryParamUserCode) {
        // Determine endpoint version
        const ssoVersion = this.ssoEndpointVersion;
        // call the SSO service to get the authorization token

        this.subscriptions.push(
          this.ssoService
            .getAuthTokenHeaders(queryParamUserCode, queryParamfdid || '', ssoVersion)
            .pipe(
              catchError(err => {
                if (err.includes('401')) {
                  this.router.navigate(['./'], { queryParams: { login: 'unauthorized' } });
                }
                if (err.includes('413')) {
                  this.notification.showError('413', 'Unable to log in. Please use the Contact Us button');
                  this.router.navigate(['./'], { queryParams: { login: 'unauthorized' } });
                }
                return throwError(err);
              })
            )
            .subscribe((val: HttpHeaders) => {
              const authToken = val.get('Authorization');
              this.userInfoService.setAuthToken(authToken);
              this.handleTokenResponse(val);
            })
        );
      } else {
        this.ssoService.getUserCode(this.ssoEndpointVersion, queryParamfdid);
      }
    });
  }

  handleTokenResponse(headers: HttpHeaders) {
    const authToken = headers.get('Authorization');
    const resetPassword = headers.get('resetPassword');
    if (resetPassword === 'true') {
      this.userInfoService.setForceResetPassword(true);
      this.router.navigate(['./account']);
    } else {
      this.blockerService.hide();
      const productReferrerUrl = localStorage.getItem('app-url');
      localStorage.setItem("app-url", environment.appConfig.bankingPortalEndpoint.url);
      if (productReferrerUrl) {
        //User is coming back from product detail login flow
        this.ssoService.getAppConfigs().subscribe(res => {
          if (res) {
            if (res.applications) {//Check if multiOrg is set
              //Multi org is set for multiple applications, need to verify if its set for product that started the login flow 
              res.applications.forEach(application => {
                application.environments.forEach(element => {
                  if (productReferrerUrl.includes(element.web_link)) {
                    this.productReferrerUrlIsValid = true;
                    if (application.environments.length > 1) {
                      this.isMultiorgSet = true;
                    }
                  }
                });

              })
              if (productReferrerUrl) {
                if (res['applications']) {
                  //If multiOrg is not set and product subscription exists navigate to respective application
                  if (!this.isMultiorgSet && this.productReferrerUrlIsValid == true) {
                    localStorage.removeItem('app-url');
                    this.userInfoService.checkForDomain(productReferrerUrl);
                  }
                  else {
                    //User is not entitled to application redirect to my applications
                    this.checkForRedirectOrigin(productReferrerUrl);
                  }
                }
              }
            }
            else {//no multiconfig set for any applications and user has come from product detail page
              if (this.productReferrerUrlIsValid == true) {
                localStorage.removeItem('app-url');
                this.userInfoService.checkForDomain(productReferrerUrl);
              }
              else {//if user is not having any entitlements redirect to my applications
                this.checkForRedirectOrigin(productReferrerUrl);
              }
            }
          }

        });
      } else {//in all other cases route to my applications
        this.checkForRedirectOrigin(productReferrerUrl);
      }
    }
  }

  private checkForRedirectOrigin(productReferrerUrl) {
    let refUrl = environment.appConfig.bankingPortalEndpoint.url + 'my-applications';
    if (productReferrerUrl === refUrl || productReferrerUrl === null || productReferrerUrl.includes('onlinealm') || this.productReferrerUrlIsValid == false) {
      this.router.navigate(['/my-applications']);
    } else {
      this.userInfoService.checkForDomain(productReferrerUrl);
    }
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }
}